import React from 'react';
import { GetServerSideProps } from 'next';
import { Head, RenderBlocks, Error, PageHeader, ContactForm } from '../components';
import { Page } from '../../payload-types';
import { fetchPage } from '../graphql/query';

export type Props = {
  page?: Page;
  statusCode: number;
};

const Page: React.FC<Props> = (props) => {
  const { page } = props;

  if (!page) {
    return (
      <Error
        title="404"
        description="The page you are looking for might have been removed or is temporarily unavailable."
      />
    );
  }

  return (
    <main>
      <Head meta={page.meta} />
      <PageHeader page={page} />
      <RenderBlocks layout={page.layout} />
    </main>
  );
};

export default Page;

export const getServerSideProps: GetServerSideProps = async (ctx) => {
  const slug = ctx.params?.slug
    ? (ctx.params.slug as string[]).join('/')
    : 'home';

  const { page } = await fetchPage(slug);

  if (!page) {
    ctx.res.statusCode = 404;

    return {
      props: {},
    };
  }

  return {
    props: {
      page,
    },
  };
};
